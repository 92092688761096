type TrackDealersOptions = {
    commonOrgId?: string;
    dealerId?: number;
    dealerName?: string;
    isDemoDealer?: boolean;
    isTestDealer?: boolean;
};

const dealerKeysToTrack = ['commonOrgId', 'dealerId', 'dealerName', 'isDemoDealer', 'isTestDealer'] as const;

export const trackDealer = (options: TrackDealersOptions = {}) => {
    if (!window.newrelic) {
        return;
    }

    for (const key of dealerKeysToTrack) {
        const value = options[key];
        if (value !== undefined) {
            const parsedValue = typeof value === 'boolean' ? String(value) : value;
            window.newrelic.setCustomAttribute(key, parsedValue);
        }
    }
};
