// externals
import type { Reducer } from 'redux';

import { payloadUtils } from '@makemydeal/dr-shared-ui-utils';
import type { FeatureToggles } from '@makemydeal/dr-platform-shared';
import { featureToggleReducer } from '@makemydeal/dr-shared-store';

// types

// action types
import { initActionTypes } from '@makemydeal/dr-shared-store';
import { AllFeatureToggles } from '@makemydeal/dr-platform-shared/typedefs/shared/types/featureToggles';

type ToggleName = keyof AllFeatureToggles;
export type ToggleOverridesMap = Partial<Record<ToggleName, ToggleName>>;

/**
 * This map defines which toggles should be overriden with another toggle value,
 * for example, enableFeatureToggle value will be ignored and enableFeatureToggleSV value will be used instead
 * if enableFeatureToggleSV is undefined, enableFeatureToggle becomes undefined as well
 */
export const chooseVehicleTogglesOverrides: ToggleOverridesMap = {};

const getOverridesValues = (featureToggles: FeatureToggles, toggleOverridesMap: ToggleOverridesMap) => {
    const overrides = Object.entries(toggleOverridesMap).reduce(
        (overridesAccumulator, [toggleToOverride, toggleOverrideSource]) => {
            overridesAccumulator[toggleToOverride as ToggleName] = featureToggles[toggleOverrideSource];

            return overridesAccumulator;
        },
        {} as FeatureToggles
    );

    return overrides;
};

/**
 * Returns a reducer that combines basic featureToggles reducer with overrides object built from the overrides map
 * @returns reducer function
 */
export const getTogglesWithOverridesReducer =
    (toggleOverridesMap: ToggleOverridesMap): Reducer<FeatureToggles> =>
    (state: FeatureToggles = {}, action: any) => {
        if (!action) {
            return state;
        }

        const newTogglesState = featureToggleReducer.reducer(state, action);

        switch (action.type) {
            case initActionTypes.INIT_PENCIL_SUCCESS: {
                const featureToggles = payloadUtils.getFeatureTogglesFromInitPayload(action.payload);
                const overrides = getOverridesValues(featureToggles, toggleOverridesMap);

                return { ...newTogglesState, ...overrides };
            }
            default: {
                return newTogglesState;
            }
        }
    };

export const togglesWithOverridesReducer = getTogglesWithOverridesReducer(chooseVehicleTogglesOverrides);
