// types
import { AdditionalSelectors } from '@makemydeal/dr-activities-change-vehicle';
import * as chooseVehicleAppSelectors from '../../selectors/chooseVehicleAppSelectors';

export const additionalSelectors: AdditionalSelectors = {
    getCommonOrgId: chooseVehicleAppSelectors.getCommonOrgId,
    getServicesBff: chooseVehicleAppSelectors.getServicesBff,
    shouldShowCost: chooseVehicleAppSelectors.isCostVisible,
    getVehicleVin: chooseVehicleAppSelectors.getVehicleVin,
    getVehicleStockNumber: chooseVehicleAppSelectors.getStockNumber
};
