// libraries
import { FSA, SimpleFSA } from '@makemydeal/dr-platform-shared';
import { PayloadWithConfig } from '@makemydeal/dr-shared-bff-types';

// types
import { bootstrapActionTypes } from '@makemydeal/dr-shared-store';
import { SetupFeatureToggleStatsInitPayload } from '@makemydeal/dr-shared-ui-utils';
import { ChooseVehicleConfig, ChooseVehicleState } from '../types/stateTreeTypes';

export type BootstrapInitialLoadAction = SimpleFSA<typeof bootstrapActionTypes.BOOTSTRAP_INITIAL_LOAD>;

export type BootstrapSuccessPayload = SetupFeatureToggleStatsInitPayload &
    PayloadWithConfig<ChooseVehicleConfig & ChooseVehicleState>;

export type BootstrapSuccessAction = FSA<typeof bootstrapActionTypes.BOOTSTRAP_SUCCESS, BootstrapSuccessPayload>;

export const initialLoad = (): BootstrapInitialLoadAction => ({ type: bootstrapActionTypes.BOOTSTRAP_INITIAL_LOAD });

export const initFromPostMessageSuccess = (payload: BootstrapSuccessPayload): BootstrapSuccessAction => ({
    type: bootstrapActionTypes.BOOTSTRAP_SUCCESS,
    payload
});
